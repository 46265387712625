// stylelint-disable selector-max-type, selector-max-combinators, selector-max-compound-selectors

.bd-toc nav {
  padding-top: .125em;
  padding-bottom: .125em;
  border-left: .25em solid #eee;

  > ul {
    padding-left: .5rem;
    margin-bottom: 0;
  }

  > ul > li {
    display: block;
  }

  li ul li {
    margin-bottom: .25rem;
    list-style-type: disc;
  }

  a code {
    font: inherit;
  }
}
