// stylelint-disable declaration-no-important

.bd-links {
  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      position: sticky;
      top: 5rem;
      z-index: 1000;
      height: calc(100vh - 7rem);
      overflow-y: auto;
    }
  }

  // Override collapse behaviors
  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

:not(.active) > .bd-sidenav {
  display: none;
}

.bd-sidenav-group-link {
  padding: .25rem .625rem .25rem .5rem;
  font-weight: 600;
  color: rgba($black, .65);
  @include border-radius(.25rem);

  > * { pointer-events: none; }

  &:hover,
  &:focus {
    color: rgba($black, .85);
    text-decoration: none;
    background-color: rgba($bd-purple-bright, .1);
  }
}

.bd-sidenav-group {
  &.has-children .bd-sidenav-group-link::before {
    display: inline-block;
    width: .875rem;
    height: .875rem;
    margin-right: .25rem;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    opacity: .5;
  }

  &.active {
    .bd-sidenav-group-link::before {
      transform: rotate(90deg);
    }

    > .bd-sidenav-group-link {
      color: rgba($black, .85);
    }
  }
}

// All levels of nav
.bd-sidebar .nav {
  padding-left: 1.25rem;

  > li > a {
    display: inline-block;
    padding: .25rem .5rem;
    @include font-size(.875rem);
    color: rgba($black, .65);
    @include border-radius(.25rem);

    &:hover,
    &:focus {
      color: rgba($black, .85);
      text-decoration: none;
      background-color: rgba($bd-purple-bright, .1);
    }
  }

  > .active > a,
  > .active:hover > a,
  > .active:focus > a {
    font-weight: 600;
    color: rgba($black, .85);
  }
}
