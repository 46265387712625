.bd-navbar {
  min-height: 4rem;
  background-color: $bd-purple-bright;

  @include media-breakpoint-down(md) {
    .navbar-nav-scroll {
      width: 100%;
      height: 2.5rem;
      margin-top: .25rem;
      overflow: hidden;

      .navbar-nav {
        padding-bottom: 2rem;
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }
    }
  }

  .navbar-nav {
    .nav-link {
      color: rgba($white, .85);

      &.active,
      &:hover,
      &:focus {
        color: $white;
      }

      &.active {
        font-weight: 600;
      }
    }
  }

  .navbar-nav-svg {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
  }
}
